import * as React from 'react'
import { useEffect, createRef } from 'react'
import { H5P } from 'h5p-standalone'

const options = {
  frameJs: '/h5p/dist/frame.bundle.js',
  frameCss: '/h5p/dist/styles/h5p.css'
}

const displayOptions = {
  frame: true,
  copyright: true,
  embed: false,
  download: false,
  icon: true,
  export: false
}

export type H5pProps = {
  workspace: string
}

const H5p: React.FC<H5pProps> = props => {
  const h5pContainer = createRef()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (h5pContainer.current) {
        const h5p = async () => {
          await new H5P(h5pContainer.current, `/h5p/${props.workspace}`, { ...options, id: props.workspace }, displayOptions)
        }
        h5p().then()
      }
    }
  }, [])

  return <div ref={h5pContainer} />
}

export default H5p
